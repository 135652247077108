@import url('https://fonts.googleapis.com/css2?family=Kanit&family=Poppins:wght@500&family=Yatra+One&display=swap');

// COLORS

:root{
    --primaryColor: #fff;
    --secondarycolor: rgb(236, 239, 243);
    --thirdColor: rgba(102, 109, 128, 0.5);
    --textColor: rgb(42,43,51);
    --shadow: rgba(149, 157, 165, 0.2);
}

// FUNCTION
@mixin flex(){
    display: flex;
    align-items: center;
}
@mixin flexA(){
    display: flex;
    justify-content: space-around;
}
@mixin flexB(){
    display: flex;
    justify-content: space-between;
}
@mixin flexAC(){
    display: flex;
    align-items: center;
    justify-content: space-around;
}
@mixin flexBC(){
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@mixin shadowCard(){
    box-shadow: var(--shadow) 0px 8px 24px;
}


// DEFAULT
*{
    padding: 0;
    margin: 0;
}
html {
    scroll-behavior: smooth;
}
input,select,textarea{
    border: none;
    outline: none;
    font-family: "Poppins";
    background-color: var(--secondarycolor);
    color: var(--textColor);
    transition: .5s;
}
button{
    border: none;
    outline: none;
    font-family: "Poppins";
    background-color: var(--textColor);
    color: var(--primaryColor);
    cursor: pointer;
    transition: .5s;
}
a{
    text-decoration: none;
    color: var(--textColor);
    transition: .5s;
}
ul{
    list-style: none;
}
h1, h2, h3, h4, h5, h6{
    font-weight: normal;
}

// BODY
body{
    background-color: var(--secondarycolor);
    color: var(--textColor);
    font-family: "Poppins";
}