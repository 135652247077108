// ============= CONSTANTS ELEMENTS ===========
@import '../../assets/styles/constants.scss';

// DETAILS PAGE STYLE
.details{
    .project-intro{
        padding: 1em;
        border-radius: 1em;
        background-color: var(--secondarycolor);
        @include flex();
        div{
            label, p{
                display: block;
                font-size: 13px;
                margin: 1em .5em;
            }
        }
        div:last-child{
            margin-left: 2em;
            opacity: .5;
        }
    }
    .project-info{
        margin: 2em 0;
        section{
            padding: .5em;
            border-radius: 50%;
            margin-right: 1em;
            color: var(--primaryColor);
            height: 30px;
            width: 30px;
            @include flex();
            justify-content: center;
            border: 5px solid var(--primaryColor);
            box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(180, 180, 180, 0.1) 0px 0px 0px 3px;
            h2{
                font-weight: bold
            }
            background-color: burlywood;
        }
        h1{
            margin: .7em 0;
        }
        .desc{
            color: var(--thirdColor);
        }
        img{
            width: 100%;
            border-radius: 1em
        }
        a{
            @include flexBC();
            padding: .7em 1em;
            width: fit-content;
            margin: 2em 0;
            background-color: var(--textColor);
            color: var(--primaryColor);
            border-radius: .5em;
            p{
                font-size: 13px;
            }
            span{
                font-size: 15px;
            }
        }
    }
}