// ============= CONSTANTS ELEMENTS ===========
@import '../../assets/styles/constants.scss';

// ABOUT PAGE STYLE
.about{
    .profile-bio{
        margin: 2em 0;
        p{
            color: var(--thirdColor);
            padding: 1em 0;
        }
        .picture{
            text-align: center;
            background-color: var(--secondarycolor);
            border-radius: 1em;
            border: 10px solid var(--primaryColor);
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgba(51, 51, 51, 0.1) 0px 0px 0px 2px;
            margin: 1em 0 2em 0;
            img{
                width: 100%;
                border-radius: 1em;
                // filter: grayscale(1);
            }
        }

    }
}